import React, { useState, useEffect, useRef } from 'react';
import { 
  Box, 
  Typography, 
} from '@mui/material';
import labellabImage from '../assets/labellab.png';
import cerealcodes from '../assets/cerealcodes.png';
import reviewers from '../assets/reviewer.jpeg';
import practice from '../assets/practice.png';
import annotate from '../assets/annotate.jpg';
import usecase from '../assets/usecase.jpeg';
import api from '../assets/api.png';
import finetune from '../assets/finetune.png';
import openai from '../assets/openai.png';

const featuresSections = [
  {
    category: 'LabelLab',
    subcategory: 'LabelLab',
    description: 'LabelLab is a platform that enables top coders to conveniently annotate synthetically generated code data through competitions with cash prizes and a practice resource for those competitions, allowing us to obtain high quality data annotations at scale. Most importantly, we will have top programmers reviewing the annotations to ensure they are high quality.',
    media: labellabImage
  },
  {
    category: 'LabelLab',
    subcategory: 'Cash Prize Competitions',
    description: 'Competitive programming competitions in particular will integrate annotations for bonus points on programming questions, and reviewers will judge the quality of annotations with top programmers reviewing the annotations. These competitions will also go to other types of programming challenges like full-stack development tasks, mobile app development tasks, data analysis tasks, and machine learning tasks.',
    media: cerealcodes
  },
  {
    category: 'LabelLab',
    subcategory: 'Reviewed Annotations',
    description: 'We will ensure the code annotations generated are correct by ensuring annotators solve a particular task through automated checks before being sent to a team of top reviewers (potentially including former winners of these competitions) to be carefully analyzed before adding to the database of annotations.',
    media: reviewers
  },
  {
    category: 'LabelLab',
    subcategory: 'Preparation Resource',
    description: 'Given the cash prize these competitions will have and the new style of competitions, participants will be inclined to prepare for these types of contests through LabelLab, which will have a much wider variety of coding tasks for participants to practice with, and this will ensure the dataset covers a wide range of coding tasks. This preparation platform will also have reviewers look at annotations before being added to the database.',
    media: practice
  },
  {
    category: 'LabelLab',
    subcategory: 'Step-by-step Annotations',
    description: 'Annotators will be directly editing the steps to an LLM generated solution (with chain-of-thought prompting) for a coding-related task, and they will also be directly editing the code (if the LLM code is way too far off, annotators will replace the code with their solution).',
    media: annotate
  },
  {
    category: 'Enterprise Specific Data',
    subcategory: 'Specific Coding Tasks',
    description: 'We will cater our competitions and data annotations to the models that are specific to enterprise-specific coding use cases (quantitative trading algorithms, machine learning research, etc.)',
    media: usecase
  },
  {
    category: 'Enterprise Specific Data',
    subcategory: 'API Access',
    description: 'When we collect data annotations, we\'ll be organizing the data for enterprise-specific use cases, and we\'ll be giving each enterprise a unique API key to provide data specific to your enterprise\'s niche use case.',
    media: api
  },
  {
    category: 'Fine Tuning Services',
    subcategory: 'Enterprise-Specific Training',
    description: 'We will use our data to train enterprise models or foundation models on our annotated synthetic code data using state-of-the-art LLM training techniques (like RAG).',
    media: finetune
  },
  {
    category: 'Fine Tuning Services',
    subcategory: 'Model Licensing',
    description: 'We will fine tune our own models on our data, and you\'ll get API access to the fine tuned models pertaining to your use cases.',
    media: openai
  }
];

const Features = () => {
  const [currentFeatureIndex, setCurrentFeatureIndex] = useState(0);
  const containerRef = useRef(null);
  const featuresRefs = useRef([]);

  useEffect(() => {
    const handleScroll = () => {
      if (!containerRef.current) return;

      const windowHeight = window.innerHeight;

      featuresRefs.current.forEach((featureRef, index) => {
        if (!featureRef) return;

        const { top, bottom } = featureRef.getBoundingClientRect();
        const featureMiddle = (top + bottom) / 2;

        if (
          featureMiddle >= 0 && 
          featureMiddle <= windowHeight
        ) {
          setCurrentFeatureIndex(index);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Box
      id="product" 
      ref={containerRef}
      sx={{ 
        height: `${featuresSections.length * 100}vh`, 
        position: 'relative',
      }}
    >
      {featuresSections.map((feature, index) => (
        <Box 
        key={index}
        ref={(el) => featuresRefs.current[index] = el}
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'sticky',
          top: 0,
          zIndex: currentFeatureIndex === index ? 10 : 1,
          opacity: currentFeatureIndex === index ? 1 : 0,
          transition: 'opacity 0.5s ease',
          pointerEvents: currentFeatureIndex === index ? 'auto' : 'none',
          px: { xs: 2, md: 10 },
          textAlign: 'center',
          mb: index === featuresSections.length - 1 ? '10vh' : 0, // Add margin to last section
        }}
      >
          <Box 
            sx={{ 
              maxWidth: 800,
              width: '100%',
            }}
          >
            <Typography 
              variant="overline" 
              color="text.secondary" 
              sx={{ 
                display: 'block',
                mb: 1,
                letterSpacing: 2,
                textTransform: 'uppercase'
              }}
            >
              {feature.category}
            </Typography>
            <Typography 
              variant="h3" 
              component="h2"
              sx={{ 
                mb: 3,
                fontWeight: 700,
                lineHeight: 1.2
              }}
            >
              {feature.subcategory}
            </Typography>
            <Typography 
              variant="body1" 
              color="text.secondary"
              sx={{ 
                fontSize: { xs: '1rem', md: '1.1rem' },
                lineHeight: 1.6,
                mb: feature.media ? 3 : 0
              }}
            >
              {feature.description}
            </Typography>
            
            {feature.media && (
              <Box 
                sx={{ 
                  mt: 4,
                  width: '100%',
                  maxHeight: 400,
                  overflow: 'hidden',
                  borderRadius: 2,
                  boxShadow: 3
                }}
              >
                <img src={feature.media} alt="LabelLab" style={{ width: '100%', height: 'auto' }} />
              </Box>
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default Features;