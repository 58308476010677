import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Typography from '@mui/material/Typography';

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexShrink: 0,
  borderRadius: `calc(${theme.shape.borderRadius}px + 8px)`,
  backdropFilter: 'blur(24px)',
  border: '1px solid',
  borderColor: (theme.vars || theme).palette.divider,
  backgroundColor: theme.vars
    ? `rgba(${theme.vars.palette.background.defaultChannel} / 0.4)`
    : alpha(theme.palette.background.default, 0.4),
  boxShadow: (theme.vars || theme).shadows[1],
  padding: '8px 12px',
}));

export default function AppAppBar() {
  const [open, setOpen] = React.useState(false);
  const [resourcesOpen, setResourcesOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <AppBar
      position="fixed"
      enableColorOnDark
      sx={{
        boxShadow: 0,
        bgcolor: 'transparent',
        backgroundImage: 'none',
        mt: 'calc(var(--template-frame-height, 0px) + 28px)',
      }}
    >
      <Container maxWidth="lg">
        <StyledToolbar variant="dense" disableGutters>
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', px: 3 }}>
            <Box
              component="a"
              href="/"
              sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'white' }}
            >
              <Typography variant="h4" component="div">
                Playdo
              </Typography>
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'flex' }, ml: 10 }}>
              <Button component="a" href="#product" variant="text" color="info" size="small" sx={{ mr: 2 }}>
                Product
              </Button>
              <Button component="a" href="#use-cases" variant="text" color="info" size="small" sx={{ mr: 2 }}>
                Use Cases
              </Button>
              <Box
                onMouseEnter={() => setResourcesOpen(true)}
                onMouseLeave={() => setResourcesOpen(false)}
                sx={{ position: 'relative' }}
              >
                <Button variant="text" color="info" size="small">
                  Resources
                </Button>
                {resourcesOpen && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '100%',
                      left: 0,
                      bgcolor: 'background.paper',
                      boxShadow: 1,
                      borderRadius: 1,
                      width: 160,
                      zIndex: 1,
                    }}
                  >
                    <MenuItem component="a" href="/blog">Blog</MenuItem>
                    <MenuItem component="a" href="https://app.playdo.ai/">Interview Prep</MenuItem>
                    <MenuItem component="a" href="/#faq">FAQ</MenuItem>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <Button
              component="a"
              href="https://calendly.com/jessechoe10/playdoai"
              variant="contained"
              size="small"
              sx={{
                bgcolor: 'white',
                color: 'primary.main',
                '&:hover': {
                  bgcolor: 'grey.100',
                },
              }}
            >
              Schedule a Call
            </Button>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' }, gap: 1 }}>
            <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="top"
              open={open}
              onClose={toggleDrawer(false)}
              PaperProps={{
                sx: {
                  top: 'var(--template-frame-height, 0px)',
                },
              }}
            >
              <Box sx={{ p: 2, backgroundColor: 'background.default' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <IconButton onClick={toggleDrawer(false)}>
                    <CloseRoundedIcon />
                  </IconButton>
                </Box>
                <MenuItem component="a" href="#product" onClick={toggleDrawer(false)}>
                  Product
                </MenuItem>
                <MenuItem component="a" href="#use-cases" onClick={toggleDrawer(false)}>
                  Use Cases
                </MenuItem>
                <MenuItem component="a" href="/blog" onClick={toggleDrawer(false)}>
                  Blog
                </MenuItem>
                <MenuItem component="a" href="https://app.playdo.ai/" onClick={toggleDrawer(false)}>
                  Interview Prep
                </MenuItem>
                <MenuItem component="a" href="/#faq" onClick={toggleDrawer(false)}>
                  FAQ
                </MenuItem>
                <Divider sx={{ my: 3 }} />
                <MenuItem 
                  component="a" 
                  href="https://calendly.com/jessechoe10/playdoai"
                  onClick={toggleDrawer(false)}
                  sx={{
                    bgcolor: 'white',
                    color: 'primary.main',
                    '&:hover': {
                      bgcolor: 'grey.100',
                    },
                  }}
                >
                  Schedule a Call
                </MenuItem>
              </Box>
            </Drawer>
          </Box>
        </StyledToolbar>
      </Container>
    </AppBar>
  );
}