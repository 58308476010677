import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        sx={{
          color: 'text.primary',
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        Frequently Asked Questions
      </Typography>
      <Box sx={{ width: '100%' }}>
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <Typography component="h3" variant="subtitle2">
              Why is Playdo.ai's data so much better?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              Using our competition styled approach with a reviewer system, we will be able to obtain high quality data at a high volume for much cheaper and better than platforms like K2View, Datacurve, or even Scale.ai.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2d-content"
            id="panel2d-header"
          >
            <Typography component="h3" variant="subtitle2">
              What happened to Playdo.ai's interview prep platform?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              We've decided to offer our interview prep platform for completely free at <Link href="https://app.playdo.ai">app.playdo.ai</Link>, and we'll be updating it with new questions and hopefully additional features in the future to ensure everyone can still prepare for their interviews, but this development will be slow given our priorities with LabelLab.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3d-content"
            id="panel3d-header"
          >
            <Typography component="h3" variant="subtitle2">
              Who do I contact if I have questions?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              You can contact us by emailing us at <Link href="mailto:team@playdo.ai">team@playdo.ai</Link>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel4'}
          onChange={handleChange('panel4')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4d-content"
            id="panel4d-header"
          >
            <Typography component="h3" variant="subtitle2">
              How can I apply for a annotation/reviewer job at Playdo.ai?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              Please contact either of us at <Link href="mailto:jessechoe10@gmail.com">jessechoe10@gmail.com</Link> or <Link href="mailto:karn.kaura251@gmail.com">karn.kaura251@gmail.com</Link> and attach your resume with a 3-4 sentence response to the following questions:
              <br></br><br></br>
              1. What experiences do you have that'll make you a good annotator/reviewer?
              <br></br>
              2. Why do you want to be a part of LabelLab?
              <br></br>
              3. Send us a link of your Codeforces and/or your GitHub with a description of your favorite project.
              <br></br>
              4. Where do you see AI growing in the next 5-10 years?
              <br></br>
              5. Would you rather be a reviewer or annotator? Why?
              <br></br><br></br>
              Please DO NOT schedule an interview through the "Schedule a Call". If we see a fit, we will contact you with next steps for a interview.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Container>
  );
}