import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default function Hero() {
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
        ...theme.applyStyles('dark', {
          backgroundImage:
            'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
        }),
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 2, sm: 5 },
        }}
      >
        <Stack
          spacing={2}
          useFlexGap
          sx={{ alignItems: 'center', width: { xs: '100%', sm: '80%' } }}
        >
          <Typography
            variant="h1"
            sx={{
              textAlign: 'center',
              fontSize: {
                xs: 'clamp(2rem, 4vw, 2rem)',
                sm: 'clamp(2.5rem, 5vw, 2.5rem)',
                md: 'clamp(3rem, 6vw, 3rem)',
                lg: 'clamp(3.5rem, 7vw, 3.5rem)',
              },
            }}
          >
            Build Intelligent Coding LLMs with Elite Annotated Synthetic Data
          </Typography>
          <Typography
            sx={{
              textAlign: 'center',
              color: 'text.secondary',
              width: { sm: '100%', md: '90%' },
            }}
          >
            Get high quality annotated synthetic data for training your LLM to write code that involves nuanced reasoning. These annotations are from the world's most elite programmers with a team of top engineers reviewing each annotation for high-quality RLHF on synthetic data. Our coding tasks range from nuanced algorithmic programming on advanced coding problems to building full-stack applications with the latest machine learning model implementations. We will also offer fine tuning services for enterprise-specific use cases.
          </Typography>
        </Stack>
        <Button
          component="a"
          href="https://calendly.com/jessechoe10/playdoai"
          variant="contained"
          color="transparent"
          size="medium"
          sx={{ 
            mt: 4,
            px: 3,
            py: 1,
            borderRadius: 2,
            fontWeight: 'bold',
            border: '1px solid',
            borderColor: 'primary.main', // Added colored outline
          }}
        >
          Schedule a Call
        </Button>
      </Container>
    </Box>
  );
}