import * as React from 'react';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/X';
import Box from '@mui/material/Box';

function Copyright() {
  return (
    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
      {'Copyright © '}
      Playdo.ai Inc.
      {' '}
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        borderTop: '1px solid',
        borderColor: 'divider',
        py: 3,
        mt: 'auto',
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 2
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: 'center',
              gap: { xs: 1, sm: 2 }
            }}
          >
            <Copyright />
            <Link color="text.secondary" variant="body2" href="/privacy" sx={{ textDecoration: 'none' }}>
              Privacy Policy
            </Link>
            <Link color="text.secondary" variant="body2" href="/terms" sx={{ textDecoration: 'none' }}>
              Terms of Service
            </Link>
          </Box>

          <Stack
            direction="row"
            spacing={1}
            sx={{ color: 'text.secondary' }}
          >
            <IconButton
              color="inherit"
              size="small"
              href="https://x.com/playdoai"
              aria-label="X"
            >
              <TwitterIcon />
            </IconButton>
            <IconButton
              color="inherit"
              size="small"
              href="https://www.linkedin.com/company/104453337"
              aria-label="LinkedIn"
            >
              <LinkedInIcon />
            </IconButton>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}