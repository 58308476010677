import React, { useState, useEffect, useRef } from 'react';
import { 
  Box, 
  Typography, 
  useTheme,
  useMediaQuery,
  IconButton,
  Slide
} from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';

// Import placeholder images (replace with actual images)
import copilot from '../assets/dev-tools-2.png';
import backend from '../assets/dev-tools-1.webp';
import ui from '../assets/dev-tools-3.jpg';
import fullstack from '../assets/dev-tools-4.webp';

import benchmarks from '../assets/academic-1.png';
import architectures from '../assets/academic-2.jpeg';
import problemsolving from '../assets/academic-3.jpg';
import finetuning from '../assets/academic-4.webp';

import trading from '../assets/fintech-1.png';
import dataanalytics from '../assets/fintech-2.png';
import algos from '../assets/fintech-3.webp';

const useCasesSections = [
  {
    category: 'Developer Tools',
    subcategory: 'Build Intelligent Developer Tools',
    description: 'Develop custom-trained models for intelligent developer copilots that can generate high-quality, production-ready backend code, design sophisticated, responsive user interfaces, and architect comprehensive full-stack applications with robust API integrations.',
    features: [
      {
        image: copilot,
        caption: 'Custom-trained Intelligent Developer Copilots'
      },
      {
        image: backend,
        caption: 'Production-Ready Backend Code with High Functionality'
      },
      {
        image: ui,
        caption: 'Sophisticated and Responsive UI Designs'
      },
      {
        image: fullstack,
        caption: 'Full-Stack Applications with Robust API Integrations'
      }
    ]
  },
  {
    category: 'Research',
    subcategory: 'Academic Research and LLM Development',
    description: 'Push the boundaries of large language models by enabling them to outperform existing models on code generation benchmarks. Implement cutting-edge code using the latest architectures and libraries, and refine models to master complex coding problems through meticulously curated fine-tuning data.',
    features: [
      {
        image: benchmarks,
        caption: 'LLMs that Outperform Code Generation Benchmarks'
      },
      {
        image: architectures,
        caption: 'Experiments with Up-to-date Architectures and Libraries'
      },
      {
        image: problemsolving,
        caption: 'LLMs that Can Solve Complex Coding Problems'
      },
      {
        image: finetuning,
        caption: 'Fine Tune SOTA Models for Code Generation'
      }
    ]
  },
  {
    category: 'FinTech',
    subcategory: 'Improve FinTech Algorithms and Models',
    description: 'Revolutionize financial technology through advanced AI-powered solutions. Automate sophisticated trading algorithm development, leverage cutting-edge data analytics for financial modeling, and innovate RegTech algorithms to transform the financial software landscape.',
    features: [
      {
        image: trading,
        caption: 'Automated Sophisticated Trading Algorithms for Financial Markets'
      },
      {
        image: dataanalytics,
        caption: 'Advanced Financial Data Analytics with High Quality Visuals'
      },
      {
        image: algos,
        caption: 'Advanced Blockchain Algorithm Development in Crypto and Banking'
      },
    ]
  }
];

const UseCases = () => {
  const [currentUseCaseIndex, setCurrentUseCaseIndex] = useState(0);
  const [currentFeatureIndex, setCurrentFeatureIndex] = useState(0);
  const [slideDirection, setSlideDirection] = useState('left'); // 'left' or 'right'
  const containerRef = useRef(null);
  const useCasesRefs = useRef([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const handleScroll = () => {
      if (!containerRef.current) return;

      const windowHeight = window.innerHeight;

      useCasesRefs.current.forEach((useCaseRef, index) => {
        if (!useCaseRef) return;

        const { top, bottom } = useCaseRef.getBoundingClientRect();
        const useCaseMiddle = (top + bottom) / 2;

        if (
          useCaseMiddle >= 0 && 
          useCaseMiddle <= windowHeight
        ) {
          setCurrentUseCaseIndex(index);
          setCurrentFeatureIndex(0); // Reset to first feature when use case changes
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Function to handle feature navigation
  const handlePrevFeature = () => {
    setSlideDirection('right');
    setCurrentFeatureIndex((prev) => 
      prev > 0 ? prev - 1 : useCasesSections[currentUseCaseIndex].features.length - 1
    );
  };

  const handleNextFeature = () => {
    setSlideDirection('left');
    setCurrentFeatureIndex((prev) => 
      prev < useCasesSections[currentUseCaseIndex].features.length - 1 ? prev + 1 : 0
    );
  };

  // Function to render the current feature with sliding transition
  const renderCurrentFeature = (feature) => {
    return (
      <Slide
        direction={slideDirection}
        in
        mountOnEnter
        unmountOnExit
        timeout={{
          enter: 1000, // Duration for enter transition
          exit: 1000,  // Duration for exit transition
        }}
      >
        <Box
          sx={{
            width: isMobile ? '90%' : '70%',
            maxWidth: 800,
            transition: 'transform 1s ease-in-out, opacity 1s ease-in-out',
          }}
        >
          <Box
            sx={{
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 3,
              overflow: 'hidden',
              boxShadow: 3,
              mb: 4,
              transition: 'transform 0.3s ease-in-out',
              '&:hover': {
                transform: 'scale(1.02)'
              }
            }}
          >
            <Box
              sx={{
                height: isMobile ? '250px' : '400px',
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: 'background.paper'
              }}
            >
              <img 
                src={feature.image} 
                alt={feature.caption}
                style={{ 
                  maxWidth: '100%', 
                  maxHeight: '100%', 
                  objectFit: 'contain' 
                }}
              />
            </Box>
            <Typography
              variant="h6"
              sx={{
                p: 3,
                textAlign: 'center',
                fontWeight: 600,
                bgcolor: 'action.hover'
              }}
            >
              {feature.caption}
            </Typography>
          </Box>
        </Box>
      </Slide>
    );
  };

  return (
    <Box
      id="use-cases" 
      ref={containerRef}
      sx={{ 
        height: `${useCasesSections.length * 100}vh`, 
        position: 'relative',
        mb: isMobile ? '100px' : '0', // Added padding bottom for mobile to prevent overlap
      }}
    >
      {useCasesSections.map((useCase, index) => (
        <Box 
          key={index}
          ref={(el) => useCasesRefs.current[index] = el}
          sx={{
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'sticky',
            top: 0,
            zIndex: currentUseCaseIndex === index ? 10 : 1,
            opacity: currentUseCaseIndex === index ? 1 : 0,
            transition: 'opacity 0.5s ease-in-out',
            pointerEvents: currentUseCaseIndex === index ? 'auto' : 'none',
            px: { xs: 2, md: 10 },
            textAlign: 'center',
          }}
        >
          <Box 
            sx={{ 
              maxWidth: 800,
              width: '100%',
              position: 'relative',
            }}
          >
            <Typography 
              variant="overline" 
              color="text.secondary" 
              sx={{ 
                display: 'block',
                mb: 1,
                letterSpacing: 2,
                textTransform: 'uppercase'
              }}
            >
              {useCase.category}
            </Typography>
            <Typography 
              variant="h3" 
              component="h2"
              sx={{ 
                mb: 3,
                fontWeight: 700,
                lineHeight: 1.2
              }}
            >
              {useCase.subcategory}
            </Typography>
            <Typography 
              variant="body1" 
              color="text.secondary"
              sx={{ 
                fontSize: { xs: '1rem', md: '1.1rem' },
                lineHeight: 1.6,
                mb: 4
              }}
            >
              {useCase.description}
            </Typography>
            
            {useCase.features && currentUseCaseIndex === index && (
              <Box 
                sx={{ 
                  position: 'relative',
                  height: isMobile ? '350px' : '500px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {renderCurrentFeature(useCase.features[currentFeatureIndex])}
                
                {/* Navigation Buttons */}
                <IconButton
                  onClick={handlePrevFeature}
                  sx={{
                    position: 'absolute',
                    left: isMobile ? '10px' : '20px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    },
                  }}
                  aria-label="Previous Feature"
                >
                  <ArrowBackIos />
                </IconButton>
                
                <IconButton
                  onClick={handleNextFeature}
                  sx={{
                    position: 'absolute',
                    right: isMobile ? '10px' : '20px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    },
                  }}
                  aria-label="Next Feature"
                >
                  <ArrowForwardIos />
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default UseCases;
