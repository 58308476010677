import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AppAppBar from './AppAppBar';
import Footer from './Footer';
import AppTheme from '../shared-theme/AppTheme';
import { Helmet } from 'react-helmet';

function BlogPost({ title, date, author, content }) {
  return (
    <Box sx={{ mb: 6 }}>
      <Typography variant="h4" component="h2" gutterBottom sx={{ mb: 2 }}>
        {title}
      </Typography>
      <Typography variant="subtitle1" color="text.secondary" gutterBottom sx={{ mb: 1 }}>
        {date}
      </Typography>
      <Typography variant="subtitle2" color="text.secondary" gutterBottom sx={{ mb: 3 }}>
        By {author}
      </Typography>
      <Typography variant="body1" sx={{ fontSize: '1.1rem', lineHeight: 1.8 }} paragraph>
        {content}
      </Typography>
    </Box>
  );
}

export default function Blog() {
  const posts = [
    {
      title: 'OpenAI Releases GPT-o1',
      date: 'December 8, 2024',
      author: 'Jesse Choe',
      content: 'ChatGPT Pro was released on December 5th, costing users $200/month. However, although o1 has a 90th percentile on Codeforces benchmarks, it\'s still incredibly difficult to rely on o1 to reason through complex coding tasks, especially since a slightly worse model (o1-preview), struggles to solve USA Computing Olympiad silver questions. However, there\'s still a lot of room for improvement for these AI models to better be able to solve these complex coding questions, and here at Playdo, we are excited to be at the forefront of this development. Also, Claude 3.5 Sonnet is yet to outperform many of OpenAI\'s models in coding tasks outside of competition coding, so there\'s, but this model still struggle to efficiently generate code for various tasks like building a full stack application, so there\'s still a lot of exciting work to be done before these models truly acheive AGI, at least in coding tasks.',
    },
  ];

  return (
    <AppTheme>
      <Helmet>
        <title>Blog</title>
      </Helmet>
      <AppAppBar />
      <Container sx={{ pt: { xs: 15, sm: 15 }, pb: { xs: 8, sm: 16 } }}>
        <Typography variant="h2" component="h1" align="center" color="primary" gutterBottom sx={{ mb: 8 }}>
          Playdo.ai Blog
        </Typography>
        {posts.map((post, index) => (
          <BlogPost key={index} {...post} />
        ))}
      </Container>
      <Footer />
    </AppTheme>
  );
}
